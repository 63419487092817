<template>
<!-- 智慧化健身房指数排名 -->
    <div class="userranking darkBack">
        <div class="userrankingnav">
            <div>用户排名</div>
            <div>
                <!-- <span class="selecte" id="selected" @click="selectedhandle">月</span> -->
                <span class="riselecte" @click="riselectedhandle">月</span>
                <span class="riselecte" @click="riselectedhandle">年</span>
                <span class="riselecte" @click="riselectedhandle">总</span>
            </div>
        </div>
        <div id="jianoption1">
            <div v-for="(sports, i) in sport" :key="i">
                <div class="personal">
                    <span>{{i+1}}</span>
                    <span>
                        <img style="width:0.38rem;height:0.38rem;border-radius:50%;" :src="sports.image" alt="">
                    </span>
                    <span style="font-size:0.19rem;color:#7EA7E2">{{sports.name}}</span>
                    <span style="width:0.17rem;height:0.17rem;border-radius:50%;">
                        <img :src="sports.gender" alt="">
                    </span>
                </div>
                <div class="timer">{{sports.timer}}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            sport:[
                {id:"1", image:require('../../../assets/images/head1.png'), name: "马诺亚", gender: require('../../../assets/images/male.png'), timer: "432,424分钟"},
                {id:"2", image:require('../../../assets/images/head2.png'), name: "荀永灏", gender: require('../../../assets/images/female.png'), timer: "362,432分钟"},
                {id:"3", image:require('../../../assets/images/head3.png'), name: "赵锐涵", gender: require('../../../assets/images/male.png'), timer: "334,225分钟"},
                {id:"4", image:require('../../../assets/images/head4.png'), name: "马娆雨", gender: require('../../../assets/images/female.png'), timer: "231,562分钟"},
                {id:"5", image:require('../../../assets/images/head5.png'), name: "李兴", gender: require('../../../assets/images/female.png'), timer: "211,116分钟"},
                {id:"6", image:require('../../../assets/images/head6.png'), name: "荀永灏", gender: require('../../../assets/images/male.png'), timer: "362,432分钟"},
                {id:"7", image:require('../../../assets/images/head7.png'), name: "赵锐涵", gender: require('../../../assets/images/male.png'), timer: "334,225分钟"},
                {id:"8", image:require('../../../assets/images/head8.png'), name: "马娆雨", gender: require('../../../assets/images/male.png'), timer: "231,562分钟"},
                {id:"9", image:require('../../../assets/images/head9.png'), name: "李兴", gender: require('../../../assets/images/male.png'), timer: "211,116分钟"},
                {id:"10", image:require('../../../assets/images/head10.png'), name: "马娆雨", gender: require('../../../assets/images/female.png'), timer: "231,562分钟"},
                {id:"11", image:require('../../../assets/images/head11.png'), name: "李兴", gender: require('../../../assets/images/female.png'), timer: "211,116分钟"},
            ],   
            
        }
    },
    methods: {
        riselectedhandle(e){          // 点击切换样式
            // $('.selecte').class('background', "#000000")
            $('.riselecte').css({'background-color':'#00000000', 'color':'#838181'})
            e.target.style.background = "#7EA7E2";
            e.target.style.color = "#000000";
            // console.log(e.target.innerHTML)
            if(e.target.innerHTML == '月') this.getUserRank("month");
            else if(e.target.innerHTML == '年') this.getUserRank("year");
            else this.getUserRank();
            
        },
        // 获取用户排名
        getUserRank(type){
            let that = this;
            let data;
            if(type == undefined){
                // data = {
                //     type:"",
                //     businessid:window.jianshenfangData.id
                // }
                type=""
            }else{
                // data = {
                //     type:type,
                //     businessid:window.jianshenfangData.id
                // }
                
            }
            
            let url = window.appHost+"/api/v1/screen/Venue/userDurationRanking?businessid="+window.jianshenfangData.id+"&type="+type
            let result = window.ajaxRequest(url,JSON.stringify(data),"post")
            console.log(result);
            that.sport = [];
            if (result.data) {
                            result.data.forEach((item,index)=>{
                that.sport.push({
                    name:item.nickname,
                    image:item.avatar == "" ? 'http://test.image.zjzxsl.com/uploads/20210807/70466024c3d3976b4a6c45c7193e0b08.jpg' : item.avatar,
                    gender:item.gender == "男" ? require('../../../assets/images/male.png') : require('../../../assets/images/female.png'),
                    timer:item.duration
                })
            })
            } else {
                
            }

        }
        
    },
    mounted () {
        this.getUserRank("month");
    }
}
</script>

<style scoped>
.userranking{
    width:5.94rem;
    height:7rem;
    font-size: 0.18rem;
    color:#fff;
    padding: 0.2rem;
    position: relative;
}
.userrankingnav{
    display: flex;
    justify-content: space-between;
}


.riselecte{
    width:33%;
    text-align: center;
    height:0.22rem;
    line-height: 0.22rem;
    display: inline-block;
    font-size: 0.12rem;
    cursor: pointer;
    color:#838181;
}

.riselecte:nth-child(1){
    background-color:#7EA7E2;
    border-radius: 0.11rem 0rem 0rem 0.11rem;
    color:#000000;
}
.riselecte:nth-child(2){
    /* background:red; */
    border-left:0.01rem solid #838181;
    border-right:0.01rem solid #838181;
}
.riselecte:nth-child(3){
    /* background:red; */
    border-radius: 0rem 0.11rem 0.11rem 0rem;
}


.userrankingnav>div:nth-child(2){
    display: flex;
    justify-content: space-around;
    width:1.05rem;
    height:0.22rem;
    border:0.01rem solid #838181;
    border-radius: 0.11rem;
}
#jianoption1{
    width:5.0rem; 
    height:5rem;
    /* border:1px solid red; */
    font-size: 0.17rem;
    color:#999999;
    margin:auto;
    margin-top:0.2rem;
    overflow: hidden;
}
#jianoption1>div{
    height:0.46rem;
    width:100%;
    /* border:1px solid red; */
    margin-bottom:0.03rem;
}

.personal{
    height:0.46rem;
    line-height: 0.46rem;;
    /* border:1px solid red; */
    float: left;
    vertical-align: middle;
}
.personal>span{
    display: inline-block;
    line-height: 0.46rem;
}
.personal>span:nth-child(1){
    width:0.2rem;
}
.personal>span:nth-child(2){
    margin:0rem 0.43rem;
}
/* .personal>span:nth-child(3){
    width:0.8rem;
} */
.personal>span:nth-child(4){
    margin-left:0.09rem;
}
.personal img{
    width:100%;
    height:100%;
    vertical-align: middle;
}
.timer{
    float: right;
    line-height: 0.46rem;
}
</style>